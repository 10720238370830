.testimony{
  margin-top: 110px;
}
/* .rh5v-DefaultPlayer_video {
  width: 70%;
  height: 70%;
} */
.gallery {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 0.8rem; 
}

.gallery .video {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
}

.gallery .video:hover {
  transform: scale(0.98);
}

.certificado{
  margin-top: 25px;
  font-size: 2.0em !important;
  color: black !important;
  font-weight: 600 !important;
  letter-spacing: 1px !important;
}


.outline-btn {
  margin-top: 25px;
  background: linear-gradient(180deg, #35bb23 26.71%, #63e029 99.36%);
  box-shadow: 0px 20px 24px 3px rgba(129, 189, 32, 0.42);
  border-radius: 34px;
  border: none;
  color: white;
  font-size: 16px;
  padding: 11px 26px 11px 26px;
  margin-left: 5rem;
}
.outline-btn:hover {
  background: white;
  cursor: pointer;
  color: var(--orange);
}


@media (max-width: 800px) {
  .gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    
  }
   .certificado{
    margin-top: 20px;
    font-size: 1.4em !important;
    color: black !important;
  }
 .outline-btn{
    margin-top: 20px;
  }
}
@media (max-width: 480px) {
    .gallery {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
      -webkit-column-width: 100%;
      -moz-column-width: 100%;
      column-width: 100%;
    }
  }

.video-container {
  border-radius: 10px;
  overflow: hidden;
}

.model{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    overflow: hidden;
    z-index: 999;
}
.model .model-close-btn{
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 5px;
    background-color: black;
    color: white;
    font-size: 2rem;
    cursor: pointer;
}

.model .video-container{
  width: auto;
  max-width: 75%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;  
  box-sizing: border-box;
  padding: 1.5rem 0 1.5rem;
  margin: 2rem auto;
}
