.videoclass{
  margin-top: 120px;
}
.gallery {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 0.8rem;
} 

.gallery .video {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: 12px;
}

.gallery .video:hover {
  transform: scale(0.98);
}

.titlevideo{
  font-style: normal;
  font-family: Inter; 
  font-weight: 700 ;
  font-size: 40px;
  
}
.titlevideo2{
  margin-top: 20px;
  font-style: normal;
  font-family: Inter; 
  font-weight: 400 ;
  font-size: 30px;
  
}
.barra{
  display: flex;
   justify-content: space-between;
  margin-top: 50px;
  margin-left: 20px;
  margin-right: 20px;
  /* margin-left: 50%; */
  /* transform: translate(-50%,-50%); */
}
.model-close-btn{
  margin-top: 100px;
}


@media (max-width: 800px) {
  .gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    
  }
}
@media (max-width: 480px) {
    .gallery {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
      -webkit-column-width: 100%;
      -moz-column-width: 100%;
      column-width: 100%;
    }
  }

.video-container {
  border-radius: 10px;
  overflow: hidden;
}

.model{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    overflow: hidden;
    z-index: 999;
}
.model .model-close-btn{
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 5px;
    background-color: black;
    color: white;
    font-size: 2rem;
    cursor: pointer;
}

.model .video-container{
  width: auto;
  max-width: 75%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;  
  box-sizing: border-box;
  padding: 1.5rem 0 1.5rem;
  margin: 2rem auto;
}