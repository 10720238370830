@import url("https://fonts.googleapis.com/css2?family=Exo+2&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Labrada&display=swap");

/* Header style */

.navbar {
  width: 100%;
  height: 100px;
  background-color: #6500ff;
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.5rem 3rem !important;
  font-size: 1.3rem !important;
}
.sticky {
  transition: all 0.3s ease-out 0s !important;
  box-shadow: 0px 10px 10px 0px  rgba(9, 5, 29, 0.171) !important;
  backdrop-filter: blur(15px) !important;
}
.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #191919;
  height: 4px !important;
  width: 28px !important;
  margin-top: 3px !important;
  margin-bottom: 5px !important;
  margin-right: 2rem !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}
.logo{
  background-image: url(../Assets/logo.png);
  background-size: cover;
  width: 90px;
  height: 80px;
}

.navbar-nav .nav-link {
  color: white !important;
  font-size: 16px !important;
  letter-spacing: 1px;
  font-weight: 500 !important;
  padding-right: 1rem !important;
  padding-left: 1.5rem !important;
}
.navbar-nav .nav-link:hover {
  color: #1eb2a6 !important;
}
.resumebtn {
  margin-left: 30px;
  border-radius: 25px;
  padding: 0 25px;
  background-color: #4158d0;
  background-image: linear-gradient(43deg, #4158d0 0%, #c850c0 100%);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 10px;
  border: none;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 0rem !important;
    background-color: #6500ff !important;
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
  .responsive-navbar {
    width: 100%;
    height: 250px;
    border-radius: 10px;
    background-color: #6500ff;
    background-image: #1eb2a6
  }
  .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .resumebtn {
    width: 150px;
    display: block;
    margin: 5px auto;
    padding: 8px 5px;
    letter-spacing: 1px;
  }
}

/* Homepage style */

.homepagebackground2 {
  height: 100vh;
  width: 100vw;
  margin-top: 100px;
  background-image: url(../Assets/bg.jpeg); 
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.row {
  padding-top: 80px;
}

.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
.Typewriter__wrapper {
  font-size: 2.0em !important;
  color: white !important;
  font-weight: 600 !important;
  letter-spacing: 1px !important;
}
.Typewriter__cursor {
  font-size: 2.4em !important;
  color: white !important;
}
.headtext {
  padding-top: 120px;
  font-size: 2.4em !important;
  color: white !important;
  font-weight: 600 !important;
  letter-spacing: 1px !important;
}
.nametext {
  font-size: 2.0em !important;
  color: white !important;
  font-weight: 600 !important;
  letter-spacing: 1px !important;
}
.imagedeveloper {
  margin-top: 50px;
  max-width: 550px;
  height: 400px;
  /*background-image: url(../Assets/Web-designer-1024x821.webp);*/
  background-size: cover;
  background-position: center;
}
.socailmediabtn {
  margin-top: 2rem;
  width: 90px;
  height: 45px;
  background-color: transparent;
  border: none;
  border-radius: 15px;
}
.socailmediabtn:hover {
  background-color: antiquewhite;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
  opacity: 0.6;
}
.icon {
  font-size: 30px;
}
.certificado{
  margin-top: 25px;
  font-size: 2.0em !important;
  color: white !important;
  font-weight: 600 !important;
  letter-spacing: 1px !important;
}


.outline-btn {
  margin-top: 25px;
  background: linear-gradient(180deg, #35bb23 26.71%, #63e029 99.36%);
  box-shadow: 0px 20px 24px 3px rgba(129, 189, 32, 0.42);
  border-radius: 34px;
  border: none;
  color: white;
  font-size: 16px;
  padding: 11px 26px 11px 26px;
  margin-left: 5rem;
}
.outline-btn:hover {
  background: white;
  cursor: pointer;
  color: var(--orange);
}

@media (max-width: 767px) {
  .homepagebackground2 {
    background-image: none;
  }
  .homepagebackground {
    width: 100%;
    height: 100%;
  }
  
  .row {
    padding-top: 50px;
  }

  .Typewriter__wrapper {
    font-size: 1.9em !important;
    color: black !important;
  }
  .Typewriter__cursor {
    font-size: 2.2em !important;
    color: black !important;
  }
  .headtext {
    padding-top: 100px;
    font-size: 2em !important;
    color: black !important;
  }
  .nametext {
    font-size: 2.4em !important;
    color: black !important;
  }
  .certificado{
    margin-top: 20px;
    font-size: 1.4em !important;
    color: black !important;
  }

  .imagedeveloper {
    margin-top: 50px;
    max-width: 420px;
    max-height: 400px;
    background-image: url(../Assets/bg.jpeg); 
  }
  .socailmediabtn {
    margin-top: 1rem;
  }
  .outline-btn{
    margin-top: 20px;
  }
 
}

/* Aboutpage style */

.aboutpagebackground {
  width: 100%;
  /* height: 100vh; */

  padding-top: 110px;
}
.textbackground {
  width: 100%;
  height: 100%;
  padding: 1rem;
  background-color: transparent;
  border-radius: 15px;
  /* box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px !important; */
    /* border: solid 5px blue; */
}
.aboutmetext {
  font-size: 2em !important;
  color: #191919 !important;
  font-weight: 600 !important;
  letter-spacing: 1px !important;
}
.aboutmetext span:hover {
  display: inline-block;
  transition: all 500ms;
  color: #fff;
  animation: vibrate 30ms linear infinite forwards;
}
@keyframes vibrate {
  0% {
    transform: translateX(-1px) translateY(1px);
  }
  100% {
    transform: translateX(1px) translateY(-2px);
  }
}
.aboutdetails {
  font-size: 1.1em !important;
}
.skilllist li {
  letter-spacing: 1px;
  font-family: "Exo 2", sans-serif;
}
.skilllist {
  font-weight: 600;
  list-style-type: "⮞  ";
}
.skilllist li:hover {
  transform: scale(1.05);
  transition: 5ms;
  color: #e8e8e8;
}
.webimage {
  margin-top: 10px;
  max-width: 700px;
  height: 700px;
   background-image: url(../Assets/about.png); 
  background-size: cover;
  background-position: center;
}
@media (max-width: 767px) {
  .aboutpagebackground {
    width: 100%;
    height: 100%;
  }
  .textbackground {
    display: block;
    margin: 0 auto;
  }
}

/* Projectpage style */

.projectbackground {
  width: 100%;
  height: 100%;
 
}
.project-section {
  position: relative !important;
  padding-top: 10px !important;
}
.project-card {
  padding-top: 20px;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.project-card-view {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px !important;
  color: #191919 !important;
  background-color: transparent !important;
  border: transparent;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
  border-radius: 15px;
}
.project-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px !important;
}

.card-img-top {
  padding: 20px !important;
  opacity: 0.8 !important;
  border-radius: 10px !important;
}
.viewbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  letter-spacing: 1px;
  color: black
}

.btn-primary {
  /* color: #fff !important; */
  background-color: transparent !important;
  border-color: #191919 !important;
  color:black
}

.btn-primary:hover {
  color: #191919 !important;
  background-color: #1eb2a6 !important;
  border-color: #c850c0 !important;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

@media (max-width: 767px) {
  .project-section{
    padding-top: 40px !important;
  }
}

/* Contactpage Style */
.contactbackground {
  width: 100%;
  height: 100vh;
  background-color: #4158d0;
  background-image: linear-gradient(
    43deg,
    #4158d0 0%,
    #c850c0 46%,
    #ffcc70 100%
  );
  padding-top: 110px;
}
.contacthead {
  padding-top: 3rem;
  text-align: center;
  font-size: 3.2rem;
  font-weight: 800;
  letter-spacing: 1px;
}
.contactpara {
  padding-top: 1rem;
  text-align: center;
  font-size: 16px;
  letter-spacing: 1px;
  padding-bottom: 1rem;
}
.contactbtn {
  display: block;
  margin: 0 auto;
  width: 150px;
  height: 50px;
  border: solid 2px;
  background-color: transparent;
  letter-spacing: 2px;
}
.contactbtn:hover {
  background-color: rgba(51, 51, 51, 0.2);
}
.line {
  margin-top: 11rem;
}
.copyright {
  padding: 1px;
  text-align: center;
  font-size: 12px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.copyright span{
  font-weight: 700;
  letter-spacing: 1px;
}
@media (max-width: 767px) {
  .line {
    margin-top: 8rem;
  }
  .contactpara br {
    display: none;
  } 
}

/* contact */
.wrapper{
  margin-top: 120px;
}

#title-Tag-Line {
  font-size: 20px;
}
/* .card-item__bg{
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  display: block;
  position: relative;
  margin: 30px auto;
  transform: translate(0px, 50px);
  z-index: 5;
} */

/* form animation starts */
.form {
  background: #fff;
  box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
  border-radius: 5px;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 5px;
  padding-bottom: 5px;
  left: 0;
  right: 0;
  position: absolute;
  border-top: 5px solid #1eb2a6;
/*   z-index: 1; */
  animation: bounce 1.5s infinite;
}
::-webkit-input-placeholder {
  font-size: 1.3em;
}

.title{
  display: block;
  font-family: sans-serif;
  margin: 10px auto 5px;
  width: 300px;
  color: #1eb2a6;
 
}
.termsConditions{
  margin: 0 auto 5px 80px;
}

.pageTitle{
  font-size: 2em;
  font-weight: bold;
}
.secondaryTitle{
  color: black;
}

.name {
  background-color: #ebebeb;
  color: #1eb2a6;
}
.name:hover {
  border-bottom: 5px solid #1eb2a6;
  height: 30px;
  width: 380px;
  transition: ease 0.5s;
}

.email {
  background-color: #ebebeb;
  height: 2em;
}

.email:hover {
  border-bottom: 5px solid #1eb2a6;
  height: 30px;
  width: 380px;
  transition: ease 0.5s;
}

.message {
  background-color: #ebebeb;
  overflow: hidden;
  height: 10rem;
}

.message:hover {
  border-bottom: 5px solid #1eb2a6;
  height: 12em;
  width: 380px;
  transition: ease 0.5s;
}

.formEntry {
  display: block;
  margin: 30px auto;
  min-width: 300px;
  padding: 10px;
  border-radius: 2px;
  border: none;
  transition: all 0.5s ease 0s;
}

.submit {
  width: 200px;
  color: white;
  background-color: #1eb2a6;
  font-size: 20px;
}

.submit:hover {
  box-shadow: 15px 15px 15px 5px rgba(78, 72, 77, 0.219);
  transform: translateY(-3px);
  width: 300px;
  background-color: #1eb2a6;
  border-top: 5px solid ;
  border-radius: 0%;
}

@keyframes bounce {
  0% {
    tranform: translate(0, 4px);
  }
  50% {
    transform: translate(0, 8px);
  }
} 
/* boton flotante */
.btn-flotante {
	/* font-size: 40px; Cambiar el tamaño de la tipografia */
	/* text-transform: uppercase; Texto en mayusculas */
	/* font-weight: bold; Fuente en negrita o bold */
	/* color:rgb(32, 113, 58);; Color del texto */
	border-radius: 200px; /* Borde del boton */
	/* letter-spacing: 5px; Espacio entre letras */
	/* background-color: #fcfafb; Color de fondo */
	/* padding: 25px 25px; Relleno del boton */
	position: fixed;
	bottom: 40px;
	right: 40px;
	transition: all 300ms ease 0ms;
	/* box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1); */
	z-index: 99;
	width: 80px;
	height: 80px;
}
.btn-flotante:hover {
	background-color: #eeeeee; /* Color de fondo al pasar el cursor */
	/* box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3); */
	transform: translateY(-7px);
}
@media only screen and (max-width: 600px) {
 	.btn-flotante {
		font-size: 14px;
		padding: 12px 20px;
		bottom: 20px;
		right: 20px;
	}
	.img-chat{
		margin-top:10px;
		/* border-radius: 100px; */
		width: 70px;
	height: 70px;
	margin-left: -15px;
	}
}

@media only screen and (max-width: 400px) {
  .btn-flotante {
   font-size: 20px;
   padding: 18px 20px;
   bottom: 20px;
   right: 20px;
 }
 .img-chat{
   margin-top:10px;
   /* border-radius: 100px; */
   width: 80px;
 height: 80px;
 margin-left: -15px;
 }
}